import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from "../../common/services/INotificationService";
import { ViewEnquiryDto, ResidentDto, UpdateEnquiryDto, UnitDto, SelectedResidentDto, ILUResidentDto, BillPayerType, IluAccommodationDto } from "../../independentLiving/Models";
import * as _ from "underscore";
import type { IStateService, IStateParamsService } from "angular-ui-router";

class EditEnquiryController {
  static $inject = ["independentLiving.service", "notification.service", "$state", "$stateParams", "$scope"];
  public enquiry: ViewEnquiryDto;
  public enquiryId: number;
  public selectedResident: ResidentDto;
  public selectedResidentId: number;
  public selectedResidents: Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
  public financialTabSelected: boolean;
    public doesFeeAndChargeExist: boolean;
    public selectedUnit : IluAccommodationDto = new IluAccommodationDto();

  public vm: any;
  private residentOrEnquiryText: string;
  public admissionDate: Date;
  public enableAddingNewResident: boolean;
  public viewDepartedMode: boolean;
  public ccEmails: string = "";
  public ccEmailDelimiter: string = ";";

  constructor(
    private readonly iluService: IIndependentLivingService,
    private readonly notificationService: INotificationService,
    private $state: IStateService,
    private $stateParams: IStateParamsService
  ) {
    this.enquiryId = $stateParams.enquiryId;
    this.doesFeeAndChargeExist = $stateParams.doesFeeAndChargeExist;

    this.selectedResidentId = $stateParams.selectedResidentId;
    this.selectedResidents = $stateParams.selectedResidents;
    this.enableAddingNewResident = $stateParams.enableAddingNewResident;
    this.financialTabSelected = $stateParams.financialTabSelected;

    this.selectedUnit = $stateParams.selectedUnit;
    this.viewDepartedMode = $stateParams.viewDepartedMode;
    this.getEnquiry();
    this.vm = this;
  }

  private getEnquiry = () => {
    let residentId = this.viewDepartedMode ? this.$stateParams.selectedResidentId : null;
    this.iluService.getEnquiry(this.enquiryId, this.viewDepartedMode, residentId).then(
      (result) => {
        this.enquiry = result;
        this.residentOrEnquiryText = this.enquiry.occupiedDate ? "Resident" : "Enquiry";
        this.selectedResidents = [];

        _.forEach(this.enquiry.residents, (prospect: ResidentDto) => {
          prospect.isLead = this.enquiry.occupiedDate ? false : true;
          this.selectedResidents.push({ residentId: prospect.id, fullName: prospect.firstName + " " + prospect.lastName, residencyId: prospect.residencyId });
        });

        this.selectedResident = _.find(this.enquiry.residents, (resident) => {
          return resident.id === this.selectedResidentId;
        });

        this.iluService.getFeesAndCharges(this.enquiry.enquiryId, this.viewDepartedMode, residentId).then((feeAndChargesResult) => {
          if (feeAndChargesResult != null && feeAndChargesResult.assignedAccommodationDto != null) {
            this.selectedUnit = feeAndChargesResult.assignedAccommodationDto; 
          } else {
            this.doesFeeAndChargeExist = false;
          }
        });

        if (this.$state.current.name == "editEnquiry.finance" || this.$state.current.name == "editIndependentLivingAllocation.finance") {
          this.financialTabSelected = true;
        }

        if (this.financialTabSelected) {
          this.goToProperFinanceState();
        }

        if (this.enableAddingNewResident) {
          var newResident = new SelectedResidentDto();
          newResident.residentId = 0;
          newResident.fullName = "New Prospect";
          this.selectedResidents.push(newResident);
          // make new prospect as selected resident
          this.initializeNewResident();
        }
      },
      () => {
        this.notificationService.error("Unexpected error occurred while retrieving independent living unit");
      }
    );
  };

  public isActive(residentId: number) {
    if (this.selectedResident.id == residentId && !this.financialTabSelected) return true;
    return false;
  }

  public goToProperFinanceState() {
    if ((this.$state.current.name == "editEnquiry" || this.$state.current.name == "editIndependentLivingAllocation") && !this.financialTabSelected) {
      if (this.enquiry.occupiedDate) {
        this.$state.go("viewIndependentLivingAllocation.finance", {
          enquiryId: this.enquiryId,
          isOccupied: this.enquiry.occupiedDate ? true : false,
          residentId: this.selectedResident.id,
          selectedResidents: this.selectedResidents,
          admissionDate: this.admissionDate,
        });
      } else {
        this.$state.go("viewEnquiry.finance", { enquiryId: this.enquiryId, isOccupied: this.enquiry.occupiedDate ? true : false, selectedResidents: this.selectedResidents });
      }
    } else if (this.financialTabSelected) {
      if (this.enquiry.occupiedDate) {
        this.$state.go("editIndependentLivingAllocation.finance", {
          enquiryId: this.enquiryId,
          viewDepartedMode: this.viewDepartedMode,
          isOccupied: this.enquiry.occupiedDate ? true : false,
          selectedResidentId: this.selectedResident.id,
          selectedUnit: this.selectedUnit,
          selectedResidents: this.selectedResidents,
          doesFeeAndChargeExist: this.doesFeeAndChargeExist,
          financialTabSelected: this.financialTabSelected,
          admissionDate: this.admissionDate,
        });
      } else {
        this.$state.go("editEnquiry.finance", {
          enquiryId: this.enquiryId,
          isOccupied: this.enquiry.occupiedDate ? true : false,
          selectedUnit: this.selectedUnit,
          selectedResidents: this.selectedResidents,
          doesFeeAndChargeExist: this.doesFeeAndChargeExist,
          financialTabSelected: this.financialTabSelected,
          admissionDate: this.admissionDate,
        });
      }
    }
  }

  public initializeNewResident() {
    var newProspect = new ResidentDto();
    newProspect.id = 0;
    newProspect.isLead = true;
    this.selectedResident = newProspect;
  }

  public saveEnquiry = (isValid) => {
    if (isValid) {
      if (this.enableAddingNewResident) {
        // add new resident to existing enquiry senario
        this.saveNewResident();
      } else {
        // usual edit enquiry senario
        this.iluService.updateEnquiry(this.setUpdateEnquiryDtoBeforeSave()).then(
          (result) => {
            this.notificationService.success(this.residentOrEnquiryText + " updated successfully!");
            if (!this.isActive(this.selectedResidentId)) {
              if (this.enquiry.occupiedDate) {
                this.$state.go("viewIndependentLivingAllocation.finance", {
                  enquiryId: this.enquiryId,
                  isOccupied: this.enquiry.occupiedDate ? true : false,
                  residentId: this.selectedResident.id,
                  selectedResidents: this.selectedResidents,
                  admissionDate: this.admissionDate,
                });
              } else {
                this.$state.go("viewEnquiry.finance", { enquiryId: this.enquiryId, isOccupied: this.enquiry.occupiedDate ? true : false, selectedResidents: this.selectedResidents });
              }
            } else {
              if (this.enquiry.occupiedDate) {
                this.$state.go("viewIndependentLivingAllocation", { enquiryId: this.enquiryId, residentId: this.selectedResident.id });
              } else {
                this.$state.go("viewEnquiry", { enquiryId: this.enquiryId, residentId: this.selectedResident.id });
              }
            }
          },
          (errors) => {
            if (errors && errors.modelStateSummary && errors.modelStateSummary.debtor) {
              this.notificationService.error("Debtor ID already exists.");
            } else {
              this.notificationService.error("Unexpected error occurred while updating " + this.residentOrEnquiryText.toLowerCase());
            }
          }
        );
      }
    }
  };

  public saveNewResident() {
    this.iluService.addResidentToEnquiry(this.setAddResidentToEnquiryDtoBeforeSave()).then(
      (result) => {
        this.notificationService.success(this.residentOrEnquiryText + " updated successfully!");
        this.$state.go("viewEnquiry", { enquiryId: this.enquiryId, residentId: result.result });
      },
      () => {
        this.notificationService.error("Unexpected error occurred while updating " + this.residentOrEnquiryText.toLowerCase());
      }
    );
  }

  public updateSelectedResident = (residentId: number) => {
    if (this.financialTabSelected) {
      if (this.enquiry != null && this.enquiry.occupiedDate) {
        this.$state.go("viewIndependentLivingAllocation", { enquiryId: this.enquiryId, residentId: residentId, viewDepartedMode: this.viewDepartedMode });
      } else {
        this.$state.go("viewEnquiry", { enquiryId: this.enquiryId });
      }
    } else if (this.vm.editResidentForm.$valid) {
      this.iluService.updateEnquiry(this.setUpdateEnquiryDtoBeforeSave()).then(
        (result) => {
          this.selectedResident = _.find(this.enquiry.residents, (resident) => {
            this.financialTabSelected = false;
            if (this.$state.current.name == "editEnquiry.finance") {
              this.$state.go("viewEnquiry", { enquiryId: this.enquiryId });
            } else if (this.$state.current.name == "editIndependentLivingAllocation.finance") {
              this.$state.go("viewIndependentLivingAllocation", { enquiryId: this.enquiryId, residentId: residentId });
            } else {
              if (this.enquiry.occupiedDate) {
                this.$state.go("editIndependentLivingAllocation", {
                  enquiryId: this.enquiryId,
                  selectedResidentId: residentId,
                  selectedUnit: this.selectedUnit,
                  selectedResidents: this.selectedResidents,
                  doesFeeAndChargeExist: this.doesFeeAndChargeExist,
                  financialTabSelected: this.financialTabSelected,
                });
              } else {
                this.$state.go("editEnquiry", {
                  enquiryId: this.enquiryId,
                  selectedResidentId: residentId,
                  doesFeeAndChargeExist: this.doesFeeAndChargeExist,
                  financialTabSelected: this.financialTabSelected,
                  enableAddingNewResident: false,
                });
              }
            }
            return resident.id === residentId;
          });
        },
        () => {
          this.notificationService.error("Unexpected error occurred while updating " + this.residentOrEnquiryText.toLowerCase());
        }
      );
    } else {
      this.financialTabSelected = false;
      if (this.$state.current.name == "editIndependentLivingAllocation") {
        this.$state.go("viewIndependentLivingAllocation", { enquiryId: this.enquiryId, residentId: this.selectedResident.id });
      } else {
        this.$state.go("viewEnquiry", { enquiryId: this.enquiryId });
      }
    }
  };

  private setUpdateEnquiryDtoBeforeSave() {
    this.setCcEmails();
    let updateEnquiryDto = new UpdateEnquiryDto();
    updateEnquiryDto.enquiryId = this.enquiryId;
    updateEnquiryDto.resident = this.selectedResident;
    return updateEnquiryDto;
  }

  private setAddResidentToEnquiryDtoBeforeSave() {
    this.setCcEmails();
    let iLUResidentDto = new ILUResidentDto();
    iLUResidentDto.enquiryId = this.enquiryId;
    iLUResidentDto.resident = this.selectedResident;
    return iLUResidentDto;
  }

  private setCcEmails() {
    this.ccEmails = this.ccEmails.replace(/,/g, this.ccEmailDelimiter);
    switch (this.selectedResident.billPayerType) {
      case BillPayerType.Resident:
        this.selectedResident.billPayerDetail.ccEmail = this.ccEmails;
        break;
      case BillPayerType.PrimaryContact:
        this.selectedResident.primaryContact.billPayerDetail.ccEmail = this.ccEmails;
        break;
      case BillPayerType.SecondaryContact:
        this.selectedResident.secondaryContact.billPayerDetail.ccEmail = this.ccEmails;
        break;
      default:
        break;
    }
  }

  public receiveCcEmails = function (data) {
    this.ccEmails = data;
  };

  public cancel = () => {
    if (this.$state.current.name == "editIndependentLivingAllocation") {
      this.$state.go("viewIndependentLivingAllocation", { enquiryId: this.enquiryId, residentId: this.selectedResident.id });
    } else {
      this.$state.go("viewEnquiry", { enquiryId: this.enquiryId, residentId: this.selectedResident.id });
    }
  };

  public refreshCallback = () => {
    this.getEnquiry();
  };

  public getNavPublisher = (): string => {
    return this.enquiry.occupiedDate ? "residents" : "leads";
  };
}

export = EditEnquiryController;
