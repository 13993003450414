"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@angular/forms");
const isNum_1 = require("./isNum");
/**
 * Validator that requires the control have a number value with a scale equal to or less than maxScale.
 *
 * @returns An error map with `scaleTooHigh` property if the scale validation check failes, otherwise `null`.
 */
function scaleValidator(maxScale) {
    return (control) => {
        const value = String(control.value || "").replace(",", "");
        if (!(value && isNum_1.isNum(value))) {
            return null;
        }
        const s = `^\\d+(.\\d{0,${maxScale}})?$`;
        const regex = new RegExp(s, "gm");
        if (!regex.test(value)) {
            return { scaleTooHigh: { maxScale } };
        }
        return null;
    };
}
exports.scaleValidator = scaleValidator;
